var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sk-collapse-container flex flex-col overflow-hidden" },
    [
      _c(
        "div",
        {
          class:
            "sk-collapse-header cursor-pointer\n      bg-background-grey\n      border-b-" +
            (!_vm.isLast ? "1" : "0") +
            " border-solid border-t-0 border-l-0 border-r-0\n      px-5 py-5 flex flex-row items-center",
          on: { click: _vm.toggleCollapse }
        },
        [
          _vm._t("header"),
          _c("vs-icon", {
            staticClass: "ml-auto",
            attrs: {
              icon: _vm.isOpen() ? "expand_less" : "expand_more",
              size: "18px"
            }
          })
        ],
        2
      ),
      _c(
        "div",
        {
          ref: "content",
          staticClass:
            "sk-collapse-content overflow-hidden ease-out duration-300 transition-max-height",
          style: { "max-height": _vm.maxHeight }
        },
        [_c("div", { staticClass: "p-8" }, [_vm._t("content")], 2)]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }