<template>
  <div class="sk-collapse-container flex flex-col overflow-hidden">
    <div :class="`sk-collapse-header cursor-pointer
        bg-background-grey
        border-b-${!isLast ? '1' : '0'} border-solid border-t-0 border-l-0 border-r-0
        px-5 py-5 flex flex-row items-center`
    " @click="toggleCollapse">
      <slot name="header"></slot>
      <vs-icon class="ml-auto" :icon="isOpen() ? 'expand_less' : 'expand_more'" size="18px"></vs-icon>
    </div>
    <div
      class="sk-collapse-content overflow-hidden ease-out duration-300 transition-max-height"
      ref="content"
      :style="{ 'max-height': maxHeight }"
    >
      <div class="p-8">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "SKCollapseItem",
    data() {
        return {
            maxHeight: "0px",
            resizeObserverInstance: null,
            isLast: false,
        };
    },
    computed:{
        accordion() {
            return this.$parent.accordion
        },
    },
    mounted() {
        this.resizeObserverInstance = new ResizeObserver(() => {
            this.changeHeight()
        })
        this.resizeObserverInstance.observe(this.$refs.content)
        this.isLast = this.askParentIfIsLast()
    },
    beforeDestroy () {
        this.resizeObserverInstance.unobserve(this.$refs.content)
    },
    methods: {
        changeHeight() {
            if (this.isOpen()) {
                this.maxHeight = `${this.$refs.content.scrollHeight}px`
            }
        },
        toggleCollapse() {
            if (this.accordion) {
                this.$parent.closeAllOtherItems(this.$el);
            }
            const maxHeightx = this.$refs.content.scrollHeight;
            if (this.maxHeight == "0px") {
                this.maxHeight = `${maxHeightx}px`;
            } else {
                this.maxHeight = `0px`;
            }
        },
        isOpen() {
            return this.maxHeight !== `0px`;
        },
        askParentIfIsLast() {
            return this.$parent.isLast(this.$el)
        },
    },
};
</script>
<style scoped>
.sk-collapse-header {
    box-shadow: 0px 0px 15px 0px rgba(210, 210, 215, 0.7);
    border-color: #d8d6de;
    border-color: rgba(216, 214, 222, 0.5);
}
</style>
