<template>
    <div class="sk-collapse rounded-lg overflow-hidden">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'SKCollapse',
    props: {
        accordion:{
            default:false,
            type: Boolean
        },
    },
    data() {
        return {
            isActive: false
        };
    },
    methods: {
        closeAllOtherItems(el) {
            let children = this.$children
            children.map((item) => {
                if(item.$el !== el) {
                    item.maxHeight = '0px'
                }
            })
        },
        isLast(el) {
            let children = this.$children
            return children.findIndex((item) => item.$el === el) === children.length -1
        },
        openItem(index) {
            this.$children[index].toggleCollapse()
        }
    }
}
</script>